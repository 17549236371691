import { setupFeatureFlags } from '@jumpcloud/ui-feature-flags';
// import Vue from 'vue';

export const {
  FeatureFlagMixin,
  FeatureFlagsModule,
  initializeFlags,
  useFeatureFlags,
} = setupFeatureFlags([
  //  {
  //    localName: 'exampleFlag',
  //    sourceId: '2024-01-09-example-feature-flag',
  //  },
]);

// Vue.mixin(FeatureFlagMixin);

declare module 'vue/types/vue' {
  interface Vue {
    $featureFlags: ReturnType<typeof FeatureFlagMixin['computed']['$featureFlags']>;
    $hasFeatureFlag: typeof FeatureFlagMixin['methods']['$hasFeatureFlag'];
  }
}
